<template>
  <div class="submit-page">
    <div class="gift-list">
      <div class="item" v-for="(item, index) in submitPageData.giftInfoList" :key="index">
        <gift-card :data="item" borderRadius></gift-card>
      </div>
    </div>

    <div class="delivery">
      <span>配送方式</span>
      <span class="fz24">快递免邮</span>
    </div>

    <div class="statistics">
      <div class="cell">
        <span>礼品数量</span>
        <span>{{allGiftNum}}</span>
      </div>
      <div class="cell">
        <span>礼品总额数</span>
        <span class="col-red">{{$utils.formatNum(allLimit)}}</span>
      </div>
      <div class="cell">
        <span>运费</span>
        <span>0</span>
      </div>
    </div>

    <div class="select-coupon">
      <div class="title">选择兑换券<!-- <van-button type="primary" @click="checkAll" >全选</van-button> --></div>
      <van-checkbox-group class="card-list" v-model="result" ref="checkboxGroup" @change="checkboxGroupChange">
        <div class="item" v-for="(item) in codeList" :key="item.id">
          <div class="coupon">
            <div class="name">兑换券</div>
            <div class="right-box">
              <span><em>{{$utils.formatNum(item.surplus_quota)}}</em>额度</span>
              <span class="date">{{item.time_str}}</span>
            </div>
          </div>
          <van-checkbox :name="item.id" checked-color="#fd6857" ref="checkboxes" :disabled="item.disabled" v-model="che"></van-checkbox>
          <p class="line1 fz20 col666 ml10 mt5" style="width: 500px; text-align: left">{{item.activity_name}}</p>
        </div>
      </van-checkbox-group>
      <p v-if="!codeList.length" class="col999 mb20" style="text-align: left">暂无对应机制礼品券可用</p>
      <div class="select-coupon-footer">
        <span class="mr20">已选择：{{result.length}}张券</span>额度：<span style="color: #fd6857">{{this.$utils.formatNum(choCouponLimit)}}</span>
      </div>
    </div>

    <div class="submit-box">
      <k-button className="submit-btn" @click="submitOrder">添加到兑换袋</k-button>
    </div>

    <van-dialog v-model="show" title="是否确认兑换此礼品？" show-cancel-button @confirm="submitOrder">
      <div class="dialog-content">
        <!-- 兑换后将扣除相应兑换券（订单一经确认提交将无法取消或更改） -->
        <p>兑换后将扣除兑换券相应的额度</p>
        <p class="col-red mt15">
          兑换券使用说明<br />
          1、本券可重复使用，直到额度扣完。<br />
          2、本券不兑现金，可找零，可重复使用，不限制使用张数。
        </p>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import GiftCard from '../components/GiftCard.vue'
import {mapState} from 'vuex'
export default {
  components: {
    GiftCard,
  },
  computed: {
    ...mapState(['submitPageData']),
    choCouponLimit() {
      let count = 0
      this.codeList.forEach(v => {
        if(this.result.includes(v.id)) {
          count += v.surplus_quota
        }
      })
      return count
    },
    allGiftNum() {
      let num = 0
      this.submitPageData.giftInfoList.forEach(v => num += v.num)
      return num
    },
    allLimit() {
      let count  = 0
      this.submitPageData.giftInfoList.forEach(v => count += v.limit * v.num)
      return count
    }
  },
  data() {
    return {
      show: false,
      result: [],
      activityList: [],
      codeList: [],
      combinationList: [],
      giftInfo: {},
	  che:true
    }
  },
  methods: {
    async submitOrder() {
        let params = this.makeParams()
        this.$toast.loading({
          message: '努力加载中...',
          forbidClick: true,
          duration: 8000
        })
        let res = await this.axios.post('client/v1.Member_Order/exchangeGitf', params)
        if(!res) return
        this.$toast.clear()
        this.$dialog.confirm({
          title: '添加成功',
          message: '已放入兑换袋，请选择继续添加礼品或提交订单',
          showCancelButton: true,
          confirmButtonText: '继续添加',
          cancelButtonText: '提交订单'
        }).then(() => {
          this.$router.replace({path:'Gift'})
        }).catch(() => {
          this.$router.replace({path: 'Bag'})
        })
		this.$nextTick(function(){
			if(this.isIntro()){
				setTimeout(() => {
				 this.$intro()
				       .setOptions({nextLabel:"下一步",prevLabel:"上一步 ",doneLabel:"我知道了",
									  steps:[
									   {
										 element: document.querySelector('.van-dialog__cancel'),
										 intro: '提交订单平台才能为您配送礼品~'
									   },
									   {
										 element: document.querySelector('.van-dialog__confirm'),
										 intro: '添加更多礼品'
				 					}]}).start();
				 		this.$utils.savelocalStorage('poolIntro',JSON.stringify({status:0}))
				}, 500)
		               
			}
							
		})
    },
	isIntro(){
		let res=this.$utils.readlocalStorage('poolIntro');
		return res==null;
	},
    async getCouponList() {
      let gitf_id = this.submitPageData.giftInfoList[0].id
      let gitf_number = this.submitPageData.giftInfoList[0].num
      let res = await this.axios.post('client/v1.Member_Order/orderSubtotal', {select_json_simple: JSON.stringify([{gitf_id,gitf_number}])})
      this.activityList = res.data.activity_list
      this.combinationList = res.data.combination_list
      this.gitfInfo = res.data.gitf_info
      let activityList = res.data.activity_list
      res.data.code_list.forEach(v => {
        v.disabled = false
        let exclusiveActiveIds = activityList.filter(v => v.is_exclusive == 1).map(v => v.id)
        if(exclusiveActiveIds.includes(v.activity_id)) {
          //该券对应的活动为专属活动
          v.exclusive = true
        } else {
          //该券对应的活动为非专属活动
          v.exclusive = false
        }
      })
      this.codeList = res.data.code_list
    },
    makeParams() {
      let obj = {
        exchange_id_str: this.result.join(',')
      }
      let gitf_id = this.submitPageData.giftInfoList[0].id
      let gitf_number = this.submitPageData.giftInfoList[0].num
      obj.select_json_simple = JSON.stringify([{gitf_id, gitf_number}])
      return obj
    },
    checkboxGroupChange() {
      // let count = 0
      // this.couponList.filter(v => value.includes(v.id)).forEach(v => count += v.quota)
      // if(count > this.allLimit)
      // if(this.choCouponLimit > this.allLimit) {
      //   this.$toast('兑换券面额对应礼品面额，且不能超出礼品总额度')
      //   this.result.splice(this.result.splice.length - 1, 1)
      // }
    },
	checkAll() {
	      this.$refs.checkboxGroup.toggleAll(true);
	    },
  },
  watch: {
    result(n) {
      if(n.length === 1) { //多选框选择第一个的时候，需要根据选择的券做对应的业务处理
        let couponId = n[0]
        let curChoCoupon = this.codeList.filter(v => v.id === couponId)[0]
        if(curChoCoupon.exclusive) {
          //如果当前选择的券是专属活动的券，则把混合类型的券给禁止掉且把不是该专属活动的专属活动券也禁止掉
          this.codeList.forEach(v => {
            if(!v.exclusive) {
              v.disabled = true
              return
            }
            if(v.exclusive && v.activity_id !== curChoCoupon.activity_id) {
              v.disabled = true
              return
            }
            v.disabled = false
          })
        } else {
          //如果当前选择的券对应的是非专属类型，且与别的活动混合，则将这些混合活动对应的券
          //开放，将专属活动的券禁止
          let relativeIds = [`${curChoCoupon.activity_id}`]
          if(this.combinationList.length) {
            this.combinationList.forEach(v => {
              let combIds = v.activity_id_str.split(',')
              if(combIds.includes(`${curChoCoupon.activity_id}`)) {
                relativeIds.push(...combIds)
              }
            })
          }
          this.codeList.forEach(v => {
            if(relativeIds.includes(`${v.activity_id}`)) {
              v.disabled = false
            } else {
              v.disabled = true
            }
          })
        }
      }
      if(n.length === 0) {
        this.codeList.forEach(v => v.disabled = false)
      }
    }
  },
  created() {
    this.getCouponList()
  }
}
</script>

<style lang="scss">
.submit-page {
  padding: 20px 20px 0;
  .dialog-content {
    padding: 25px 30px 15px;
    font-size: 26px;
    color: #666;
    text-align: left;
    line-height: 40px;
  }
  .gift-list {
    padding-top: 1px;
    .item {
      margin-bottom: 1px;
    }
  }
  .delivery {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;
    padding: 0 20px;
    height: 85px;
    background-color: #fff;
    border-radius: 15px;
  }
  .statistics {
    padding: 20px;
    border-radius: 15px;
    background-color: #fff;
    .cell {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 50px;
      font-size: 24px;
    }
  }
  .select-coupon {
    margin-top: 20px;
    padding: 0 20px;
    border-radius: 15px;
    background-color: #fff;
    .title {
      font-weight: 900;
      height: 80px;
      line-height: 80px;
      text-align: left;
    }
    .item {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: 20px;
    }
    .coupon {
      display: flex;
      align-items: center;
      margin-right: 12px;
      width: 610px;
      height: 60px;
      box-sizing: border-box;
      background-image: url('../assets/images/coupon-border-bg.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      .name {
        width: 175px;
        font-size: 28px;
        text-align: center;
      }
      .right-box {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        padding: 0 15px 0 6px;
        color: #fb9066;
        flex: 1;
        font-size: 20px;
        em {
          margin-right: 2px;
          font-style: inherit;
          font-size: 30px;
        }
      }
    }
    .select-coupon-footer {
      text-align: right;
      border-top: 1px solid #d9d9d9;
      height: 80px;
      line-height: 80px;
      padding: 0 20px;
      font-size: 24px;
    }
  }
  .submit-box {
    padding: 50px 0;
    .submit-btn {
      margin: 0 auto;
      width: 600px;
      height: 65px;
      border-radius: 40px;
    }
  }
}
</style>