<template>
  <div class="result-page">
    <img class="success-img" src="../assets/images/gift-open.png" alt="成功">
    <p class="fz32 mt10">兑换成功</p>
    <p class="mt20 col666">兑换礼品将在3-5个工作日内发货，感谢您的耐心等待及对保乐力加中国公司支持，谢谢！</p>
    <div class="receiver-box">
      <div class="row">
        <span class="left">收货人：</span>
        <span class="right">{{$route.params.consignee_name}} <span class="ml10">{{$route.params.consignee_mobile}}</span></span>
      </div>
      <div class="row">
        <span class="left">收货地址：</span>
        <span class="right">{{$route.params.address}}</span>
      </div>
    </div>

    <div class="btn-box">
      <k-button className="left-btn" mode="deep-blue" @click="$router.replace('Gift')">继续兑换</k-button>
      <k-button className="right-btn" mode="light-blue" @click="$router.replace('Bag')">我的兑换袋</k-button>
    </div>
  </div>
</template>

<script>
export default {
  created() {
  }
}
</script>

<style lang="scss">
.result-page {
  padding: 0 20px;
  .success-img {
    margin: 80px auto 0;
    width: 164px;
    height: 130px;
  }
  .receiver-box {
    margin-top: 50px;
    padding: 20px;
    background-color: #fff;
    text-align: left;
    border-radius: 15px;
  }
  .row {
    display: flex;
    align-items: flex-start;
    min-height: 55px;
    .left {
      flex-shrink: 0;
      width: 5em;
      text-align-last: justify;
    }
  }
  .btn-box {
    display: flex;
    justify-content: space-around;
    margin-top: 80px;
    .left-btn,
    .right-btn {
      width: 260px;
      height: 75px;
      color: #fff;
      font-size: 28px;
      border-radius: 50px;
    }
  }
}
</style>