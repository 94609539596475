function px2vw(px) {
  return `${(px / 7.5).toFixed(6)}vw`
}

var md5 = require('./md5.js')
/**
 * @param {Object} data 请求数据的参数
 * 请求数据的签名验证
 * 处理后的字符串 = 对数组的值按key升序，然后按照升序后的键值拼接成字符串
 * md5(api_key + 处理后的字符串 + api_secret) 后转为大写
 */
function getSign(data, key, secret) {
	// 对数组的值按key升序
	let keys = Object.keys(data).sort();
	let obj = {}
	for (let i = 0; i < keys.length; i++) {
		let index = keys[i];
		if(data[index] !== '' && data[index] !== undefined && data[index] !== null) {
			obj[index] = data[index];
		} else {
			delete data[index]
		}
	}

	let objString = ''
	//  处理字符串
	for (let i in obj) {
		let data2 = ''
		let datas = obj[i]
		if (typeof(datas) == 'string') {
			data2 = i + datas + ''
		} else {
			data2 = i + JSON.stringify(datas) + ''
		}
		objString += data2
	}

	// 拼接成字符串
	objString = key + objString + secret
  // MD5加密并转为大写
	return md5(objString).toUpperCase()
}

function formatNum(num) {
	if(!num) return ''
	let numStr = num + ''
	let arys = numStr.split('')
	for(let i = arys.length - 1, count = 0; i > 0; i--) {
		count++
		if(count % 3 === 0) {
			arys.splice(i , 0, ',')
		}
	}
	return arys.join('')
}
function savelocalStorage(key,item){
		localStorage.setItem(key,item)
}
function readlocalStorage(key){		
		return JSON.parse(localStorage.getItem(key))
}

export default {
  px2vw,
	getSign,
	formatNum,
	savelocalStorage,
	readlocalStorage
}