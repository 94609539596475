<template>
  <div class="edit-address-page">
    <div class="row">
      <span class="title">收货人</span>
      <input v-model="name" type="text" placeholder="请输入收货人姓名" />
    </div>
    <div class="row">
      <span class="title">联系电话</span>
      <input v-model="phone" type="tel" placeholder="请输入收货人联系电话" />
    </div>
    <div class="row">
      <span class="title">省市区</span>
      <span @click="show = true" :class="[{col999: !addressFormat}]">{{addressFormat || '请选择省市区'}}</span>
    </div>
    <div class="row">
      <span class="title">详细地址</span>
      <input v-model="detailAddress" type="text" placeholder="如街道，楼牌号等">
    </div>
    <div class="row">
      <span class="title" style="margin-right: auto">设为默认</span>
      <van-checkbox checked-color="#fd6857" icon-size="16" v-model="isDefault"></van-checkbox>
    </div>

    <div>
      <k-button class="save-btn" @click="saveAddress">保存地址信息</k-button>
    </div>

    <van-popup v-model="show" position="bottom">
      <van-area
        title="省市区选择"
        :area-list="areaList"
        :columns-placeholder="['请选择', '请选择', '请选择']"
        @confirm="areaConfirmHandler"
      />
    </van-popup>
  </div>
</template>

<script>
import areaList from '../assets/js/area'
import KButton from '../components/KButton.vue';
export default {
  components: { KButton },
  computed: {
    addressFormat() {
      if(this.province && this.city && this.district) {
        return `${this.province}/${this.city}/${this.district}`
      } else {
        return ''
      }
    }
  },
  data() {
    return {
      show: false,
      areaList,
      searchResult: [],
      name: '',
      phone: '',
      province: '',
      city: '',
      district: '',
      detailAddress: '',
      isDefault: false
    };
  },
  methods: {
    async saveAddress() {
      let params = this.makeParams()
      if(this.isEdit) {
        let res = await this.axios.post('client/v1.Member_Address/updateAddress', params)
		
        if(!res) return
      } else {
        let res = await this.axios.post('client/v1.Member_Address/addAddress', params)
        if(!res) return
      }
      this.$router.go(-1)
    },
    makeParams() {
      let obj = {
        consignee_name: this.name,
        consignee_mobile: this.phone,
        province_name: this.province,
        city_name: this.city,
        district_name: this.district,
        specific_address: this.detailAddress,
        default: this.isDefault ? 1 : 0
      }
      if(this.isEdit) {
        obj.address_id = this.id
      }
      return obj
    },
    areaConfirmHandler(e) {
      this.show = false
      if(e.includes(undefined) || (e.map(v => v.name).filter(v => v).length !== 3)) {
        this.$toast('请完善省市区信息')
        return
      }
      this.province = e[0].name
      this.city = e[1].name
      this.district = e[2].name
    }
  },
  created() {
	Object.assign(this, this.$route.params)
    if(this.$route.params.id>0) {      
      this.isEdit = true
    }
  }
};
</script>

<style lang="scss">
  .edit-address-page {
    .row {
      display: flex;
      align-items: center;
      margin-top: 20px;
      padding: 0 20px;
      height: 80px;
      background-color: #fff;
      text-align: left;
      .title {
        width: 6em;
      }
      ::-webkit-input-placeholder {
        color: #999;
      }
    }
    .save-btn {
      margin-top: 100px;
      width: 600px;
      height: 70px;
      border-radius: 40px;
    }
  }
</style>
