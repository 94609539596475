<template>
  <div>
		<div class="fixed-wrap">
			<div class="slot">
				<slot />
			</div>
      <div style="padding-bottom: env(safe-area-inset-bottom); background-color: #fff"></div>
		</div>
		<div class="fixed-placeholder" :style="{height}"></div>
	</div>
</template>

<script>
export default {
  data() {
    return {
      height: '0vw',
    }
  },
  mounted() {
    let rect = document.querySelector('.slot').getBoundingClientRect()
    let ratio = window.devicePixelRatio
    this.height = this.$utils.px2vw(rect.height * ratio)
  }
}
</script>

<style>
.fixed-wrap {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}
.fixed-placeholder {
  box-sizing: content-box;
  padding-bottom: env(safe-area-inset-bottom);
 }
</style>