import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import utils from './assets/js/utils'
import axios from './assets/js/axios'
import VueAxios from 'vue-axios'
import VueLazyLoad from 'vue-lazyload'
import intro from 'intro.js' // introjs库
import 'intro.js/introjs.css' // introjs默认css样式
import 'intro.js/themes/introjs-flattener.css' // introjs主题

Vue.config.productionTip = false

Vue.use(VueLazyLoad)
//Vue.use(VueIntro)

import { Button, NavBar, Image, Tabbar,TabbarItem, List, Area ,
PullRefresh, Icon, Cell,CellGroup, RadioGroup, Radio,Popup ,
Search, Lazyload, Swipe, SwipeItem, Checkbox,CheckboxGroup, 
Stepper, SwipeCell,Grid, GridItem, Tab, Tabs,Empty,Toast,
Field,Dialog, Step, Steps, DropdownMenu, DropdownItem, Sticky,Sidebar, SidebarItem,Col, Row} from 'vant'
Vue.use(Sidebar)
Vue.use(SidebarItem)
Vue.use(Sticky)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(RadioGroup)
Vue.use(Radio)
Vue.use(Button)
Vue.use(NavBar)
Vue.use(Image)
Vue.use(Tabbar)
Vue.use(TabbarItem)
Vue.use(List)
Vue.use(PullRefresh)
Vue.use(Search)
Vue.use(Lazyload)
Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.use(Stepper)
Vue.use(SwipeCell)
Vue.use(Icon)
Vue.use(Cell)
Vue.use(Grid)
Vue.use(GridItem)
Vue.use(CellGroup)
Vue.use(Tab)
Vue.use(Tabs)
Vue.use(Empty)
Vue.use(Toast)
Vue.use(Field)
Vue.use(Area)
Vue.use(Popup)
Vue.use(Dialog)
Vue.use(Step)
Vue.use(Steps)
Vue.use(Col)
Vue.use(Row)
Vue.use(VueAxios, axios)
import KButton from './components/KButton.vue'
Vue.component('KButton', KButton)
Vue.prototype.$utils = utils
Vue.prototype.$intro = intro


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
