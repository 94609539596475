<template>
  <div class="my-order-page">
    <van-tabs
      v-model="active"
      sticky
      animated
      swipeable
      @change="tabChangeHandle"
    >
      <van-tab title="待发货">
        <van-list
          :immediate-check="false"
          v-model="loading1"
          :finished="finished1"
          finished-text="没有更多了"
          @load="getOrderList"
          class="list-wrap"
        >
          <div
            class="mt20"
            v-for="item in waitSendList"
            :key="item.id"
            @click="
              $router.push({ path: 'OrderDetail', query: { id: item.id } })
            "
          >
            <order-card :data="item"></order-card>
          </div>
        </van-list>
      </van-tab>
      <van-tab title="待收货">
        <van-list
          :immediate-check="false"
          v-model="loading2"
          :finished="finished2"
          finished-text="没有更多了"
          @load="getOrderList"
          class="list-wrap"
        >
          <div
            class="mt20"
            v-for="item in waitReceiveList"
            :key="item.id"
            @click="
              $router.push({ path: 'OrderDetail', query: { id: item.id } })
            "
          >
            <order-card :data="item"></order-card>
          </div>
        </van-list>
      </van-tab>
      <van-tab title="已收货">
        <van-list
          :immediate-check="false"
          v-model="loading3"
          :finished="finished3"
          finished-text="没有更多了"
          @load="getOrderList"
          class="list-wrap"
        >
          <div
            class="mt20"
            v-for="item in hasTakeList"
            :key="item.id"
            @click="
              $router.push({ path: 'OrderDetail', query: { id: item.id } })
            "
          >
            <order-card :data="item"></order-card>
          </div>
        </van-list>
      </van-tab>
    </van-tabs>
	<cantact-us />
  </div>
</template>

<script>
import OrderCard from "../components/OrderCard.vue";
import CantactUs from '../components/CantactUs.vue'
export default {
  name: "myOrder",
  components: {
    OrderCard,
	CantactUs
  },
  data() {
    this.pageSize = 10;
    return {
      active: +this.$route.query.active || 0,
      waitSendList: [],
      waitReceiveList: [],
      hasTakeList: [],
      loading1: false,
      finished1: false,
      loading2: false,
      finished2: false,
      loading3: false,
      finished3: false,
    };
  },
  methods: {
    async getOrderList() {
      let params = this.makeParams();
      let res = await this.axios.post(
        "client/v1.Member_Order/orderList",
        params
      );
      if (!res) return;
      this.appendListData(res.data.list);
    },
    clear() {
       if (this.active == 0) {
        //待发货
        this.waitSendList = []
        this.loading1 = true
        this.finished1 = false
      } else if (this.active == 1) {
        //待收货
        this.waitReceiveList = []
        this.loading2 = true
        this.finished2 = false
      } else {
        //已收货
        this.hasTakeList = [];
        this.loading3 = true
        this.finished3 = false
      }
    },
    appendListData(list) {
      if (this.active == 0) {
        //待发货
        this.waitSendList.push(...list);
        this.loading1 = false;
        if (this.waitSendList.length < this.pageSize) {
          this.finished1 = true;
        }
      } else if (this.active == 1) {
        //待收货
        this.waitReceiveList.push(...list);
        this.loading2 = false;
        if (this.waitReceiveList.length < this.pageSize) {
          this.finished2 = true;
        }
      } else {
        //已收货
        this.hasTakeList.push(...list);
        this.loading3 = false;
        if (this.hasTakeList.length < this.pageSize) {
          this.finished3 = true;
        }
      }
    },
    makeParams() {
      let obj = {};
      obj.page_num = this.pageSize;
      if (this.active === 0) {
        //待发货
        obj.status = 1;
        obj.cur_page = parseInt(this.waitSendList.length / this.pageSize) + 1;
      } else if (this.active === 1) {
        //待收货
        obj.status = 2;
        obj.cur_page =
          parseInt(this.waitReceiveList.length / this.pageSize) + 1;
      } else {
        //已收货
        obj.status = 3;
        obj.cur_page = parseInt(this.hasTakeList.length / this.pageSize) + 1;
      }
      return obj;
    },
    tabChangeHandle() {
      this.clear()
      this.getOrderList();
    },
  },
  beforeRouteEnter(to, from, next) {
    if (from.name !== "OrderDetail") {
      next((vm) => {
        vm.active = +to.query.active
        vm.clear()
        vm.getOrderList()
      });
    }
    next();
  },
};
</script>

<style lang="scss">
.my-order-page {
  .van-tab--active {
    color: $deep-blue-color;
  }
  .van-tabs__line {
    background-color: $deep-blue-color;
  }
  .list-wrap {
    padding: 0 20px;
  }
}
</style>
