<template>
  <div class="login-page">
    <img class="logo" src="../assets/images/logo.png" />
    <p class="mt35 fz32">欢迎登录</p>
    <p class="col-deep-blue fz28">礼品兑换商城</p>
    <div class="form">
      <div class="form-item">
        <label for="phone">登录账号</label>
        <div class="input-box"><input v-model="phone" id="phone" placeholder="请输入手机号码" type="tel" :maxlength="11" /></div>
      </div>
      <div class="form-item">
        <label for="code">验证码</label>
        <div class="input-box">
          <input v-model="code" id="code" placeholder="请输入验证码" type="tel" :maxlength="6" />
          <count-down style="height: 100%" ref="countDown" @count="count" @countStop="countStop">
            <button class="code-btn" :disabled="wait" @click="getCode">{{wait ? `${countDown}秒后可重发` : '获取验证码'}}</button>
          </count-down>
        </div>
      </div>
    </div>
    <div>
      <k-button className="btn gray-shadow" :loading="loading" @click="login">登录 →</k-button>
    </div>

    <cantact-us />
  </div>
</template>

<script>
import CountDown from '../components/CountDown'
import CantactUs from '../components/CantactUs.vue'
import {mapState} from 'vuex'
export default {
  components: {
    CountDown,
    CantactUs
  },
  computed: {
    ...mapState(['token']),
  },
  data() {
    return {
      phone: '',
      code: '',
      countDown: 60,
      wait: false,
      loading: false,
    }
  },
  methods: {
    async getCode() {
      let res = await this.axios.post('client/v1.Common/sendSMS',{phone: this.phone})
      if(!res) return
      this.wait = true
      this.$refs.countDown.startCount()
    },
    count(num) {
      this.countDown = num
    },
    countStop() {
      this.countDown = 60
      this.wait = false
    },
    async login() {
      this.loading = true
      let res = await this.axios.post('client/v1.Member/smsLogin',{phone: this.phone, code: this.code})
      if(!res) {
        this.loading = false
        return
      }
      this.$store.commit('login', res.data.jwt)
      this.loading = true
	  //在这里判断一下，是不是刚刚传过来的字段
	
	if (this.$route.query.auth=="0"){
		this.$router.go(-1);
	}else{
      this.$router.replace('Gift')
	  }
    }
  },
  created() {
    if(this.token) {
      this.$router.replace('Gift')
    }
  }
}
</script>

<style lang="scss">
  .login-page {
    .logo {
      margin-top: 90px;
      width: 461px;
      height: 163px;
    }

    

    .form {
      margin-top: 75px;
      .form-item {
        text-align: left;
        margin-bottom: 45px;
        label {
            margin-left: 100px;
          }
        .input-box {
          margin: auto;
          margin-top: 15px;
          display: flex;
          width: 600px;
          height: 90px;
          box-shadow: 0 0 15px 5px #d2dbe5;
          overflow: hidden;
          border-radius: 12px;
          input {
            border: 0;
            outline: none;
            flex: 1;
            width: 200px;
            height: 100%;
            padding: 0 25px;
          }
          .code-btn {
            display: inline-block;
            width: 200px;
            background-color: #fff;
            font-size: 28px;
            color: $deep-blue-color;
            height: 100%;
          }
        }
      }
    }

    .btn {
      margin-top: 60px;
      font-size: 28px;
      width: 400px;
      border-radius: 20px;
      height: 70px;
    }
}
  
</style>