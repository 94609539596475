import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
import axios from '../assets/js/axios'
import route from '../router/index'

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState({
    reducer(state) {
      return {
        token: state.token,
        submitPageData: state.submitPageData,
      }
    }
  })],
  state: {
    token: '',
    isLogin: false,
    submitPageData: {}, //订单提交页所需要的数据
    defaultAddressInfo: {}, //用户默认的地址信息数据,
    sysConfig: {},
	notifyData: {},
  },
  mutations: {
    login(state, payload) {
      state.token = payload
      state.isLogin = true
    },
    loginOutMut(state) {
      state.token = ''
      state.isLogin = false
      window.localStorage.clear()
      route.push('Login')
    },
    sysConfigMut(state, payload) {
      state.sysConfig = payload
    },
    submitPageDataMut(state, payload) {
      state.submitPageData = payload
    },
    defaultAddressMut(state, payload) {
      state.defaultAddressInfo = payload
    },
	notifyDataMut(state, payload) {
	  state.notifyData = payload
	}
  },
  actions: {
    async defaultAddressAct({commit, state}) {
      if(!state.defaultAddressInfo.id) {
        let res = await axios.get('client/v1.Member_address/getDefaultAddress')
        commit('defaultAddressMut', {
          id: res.data.id,
          name: res.data.consignee_name,
          phone: res.data.consignee_mobile,
          province: res.data.province_name,
          city: res.data.city_name,
          district: res.data.district_name,
          detailAddress: res.data.specific_address,
          fullAddress: res.data.full_address
        })
      }
    },
    async sysConfigAct({commit, state}) {
      if(Object.keys(state.sysConfig).length) return true
      let res = await axios.get('client/v1.Common/getSiteInfo')
      commit('sysConfigMut', res.data);
    }
  },
  modules: {
  }
})
