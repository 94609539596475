<template>
  <div class="gift-detail">
    <van-swipe class="swipe" :autoplay="3000" indicator-color="white">
      <van-swipe-item v-for="(item, index) in giftDetail.carousel_list" :key="index">
        <img v-lazy="item" />
      </van-swipe-item>
    </van-swipe>

    <div class="info-card">
      <div class="top">
        <span class="num">{{$utils.formatNum(giftDetail.quota)}}</span>
        <span class="limit">额度</span>
        <span class="paid">
          已兑：{{giftDetail.total_exchange_num}}，剩余库存：{{giftDetail.stock}}
        </span>
      </div>
      <p class="title">{{giftDetail.gitf_name}}</p>
      <p class="sub-title">{{giftDetail.small_title}}</p>
    </div>
    <!-- v-lazy-container="{ selector: 'img' }" https://bllj.oss-cn-shenzhen.aliyuncs.com/uniOss/video/2022/11/16/web1668614267113GMtSz.mp4-->
    <div v-if="giftDetail.content" class="images-box">
		<video v-if="giftDetail.video_url" controls  class="video-box" width="100%" controlslist="nodownload noremoteplayback" oncontextmenu="return false"  height="240"
		:src="giftDetail.video_url">
			您的浏览器不支持 video 标签。
		<source src type="video/mp4" />
		</video>
      <div v-html="beautifyRichText(giftDetail.content)"></div>
    </div>
	
	

    <van-popup v-model="show" position="bottom">
      <div class="num-choose-box">
        <div class="row">
          <span>数量</span>
         <van-stepper v-model="giftNum" min="1" max="99" integer theme="round" />
        </div>
        <div class="row">
          <span>消耗面额</span>
         <span class="col-red mr10 fz32">{{$utils.formatNum(+this.giftDetail.quota * this.giftNum)}}</span>
        </div>
        <k-button v-if="quickBuy" className="quick-buy-btn" mode="deep-blue" @click="changeNow">放入兑换袋</k-button>
        <!-- <k-button v-else className="add-bag-btn" mode="light-blue" @click="addToBag">放入兑换袋</k-button> -->
      </div>
    </van-popup>

    <fixed-bottom>
      <div class="footer">
          <k-button className="left-btn" mode="deep-blue" @click="showBuyNowDialog">{{giftDetail.sure_exchange === 1 ? '添加到待兑换区' : '暂无兑换券'}}</k-button>
          <!-- <k-button className="right-btn" mode="light-blue" @click="show=true; quickBuy=false">添加到待兑换区</k-button> -->
      </div>
    </fixed-bottom>
  </div>
</template>

<script>
import FixedBottom from '../components/FixedBottom.vue'
import KButton from '../components/KButton.vue'
export default {
  components: {
    FixedBottom,
    KButton
  },
  data() {
    return {
      giftDetail: {},
      giftNum: 1,
      show: false,
      quickBuy: false
    }
  },
  methods: {
    async getGiftDetail() {
      let res = await this.axios.get('client/v1.Gitf/getGitfInfo', {params: {gitf_id: this.$route.query.id,gift_type:this.$route.query.t}})
      this.giftDetail = res.data
    },
    async addToBag() {
      let res = await this.axios.post('client/v1.Member_Cart/addCart', {gitf_id: this.giftDetail.id, gitf_number: this.giftNum})
      if(!res) return
      this.$toast(res.msg)
      this.show = false
      this.$dialog.confirm({
        message: '已成功放入兑换袋~',
        cancelButtonText: '继续兑换',
        confirmButtonText: '去结算'
      }).then(()=>{
        this.$router.push('Bag')
      })
    },
    showBuyNowDialog() {
      if(this.giftDetail.sure_exchange === 1) {
        this.show=true
        this.quickBuy=true
      }
    },
    changeNow() {
      this.$store.commit('submitPageDataMut', {
        giftInfoList: [{
          id: this.giftDetail.id,
          num: this.giftNum,
          img: this.giftDetail.carousel_list[0],
          name: this.giftDetail.gitf_name,
          limit: this.giftDetail.quota,
          activeName: this.giftDetail.small_title
        }]
      })
      this.$router.push({path: 'AddToPool', query: {quickBuy: true}})
    },
    beautifyRichText(str) {
      if(!str) return
      // .replace(/src/g, 'data-src')
      return (str.replace(/<img/g, '<img style="max-width: 100%"'))
    }
  },
  created() {
    this.getGiftDetail()
  }
}
</script>

<style lang="scss">
.gift-detail {
  .swipe {
    margin-top: 20px;
    height: 600px;
    background-color: #fff;
    img {
      max-width: 100%;
      height: 600px;
    }
  }
  .info-card {
    margin: 20px;
    padding: 20px;
    background-color: #fff;
    border-radius: 15px;
    .top {
      display: flex;
      align-items: baseline;
      font-size: 20px;
      .num {
        margin-right: 5px;
        font-size: 40px;
        color: $col-red;
      }
      .limit {
        margin-right: auto;
        color: $col-red;
      }
    }
    .title {
      margin: 20px 0;
      text-align: left;
      color: #1a1a1a;
      font-size: 32px;
      line-height: 40px;
    }
    .sub-title {
      text-align: left;
      font-size: 24px;
      color: #808080;
    }
  }

  .images-box {
    padding: 0 20px;
    .img {
      max-width: 100%;
    }
  }

  .footer {
    display: flex;
    justify-content: space-around;
    align-items: center;
    box-shadow: 0 -2px 3px #f1f1f1;
    height: 100px;
    background-color: #fff;
    .left-btn, 
    .right-btn {
      width: 70%;
      height: 75px;
      color: #fff;
      font-size: 28px;
      border-radius: 50px;
    }
  }
  .num-choose-box {
    padding: 20px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    .row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 80px;
      font-size: 28px;
    }
    .quick-buy-btn,.add-bag-btn {
      margin-top: 20px;
      width: 600px;
      height: 65px;
      border-radius: 40px;
    }
    .van-stepper--round .van-stepper__plus {
      background-color: $deep-blue-color;
    }
    .van-stepper--round .van-stepper__minus {
      color: $deep-blue-color;
      border-color: $deep-blue-color;
    }
  }
}
</style>