<template>
  
    <div class="active-info-box">
      <div v-html="sysConfig.activity_explain" class="fz24"></div>
    </div>

    
</template>

 <script>
import { mapActions,mapState } from "vuex";
export default {
  name: "Clause",
  data() {
    return {
		
    };
  },
  computed: {
    ...mapState(["sysConfig"])	
  },
  methods: {
    ...mapActions(['sysConfigAct'])  
  },
  activated(){
    this.sysConfigAct()
  }
  
};
</script>
<style lang="scss">


  .active-info-box {
    margin-top: 20px;
    padding: 30px;
    text-align: justify;
  }

</style>