<template>
  <van-button 
    :class="[className, shadowClass]" 
    :icon="iconName" 
    :icon-position="iconPosition" 
    :color="color"
    :loading="loading"
    @click="click"
  >
    <slot/>
  </van-button>
</template>

<script>
export default {
  props: {
    className: String,
    iconName: String,
    iconPosition: {
      type: String,
      default: 'right'
    },
    loading: Boolean,
    mode: {
      type: String,
      default: 'linear-deep-blue'
    },
  },
  computed: {
    color() {
      if(this.mode === 'linear-deep-blue') {
        return 'linear-gradient(to bottom, #7fa5d0, #023466)'
      } else if(this.mode === 'deep-blue') {
        return '#023466'
      } else if(this.mode === 'light-blue') {
        return '#7fa5d0'
      }
      return '#fff'
    },
    shadowClass() {
      if(this.mode === 'deep-blue') {
        return 'deep-blue-shadow'
      } else if(this.mode === 'light-blue') {
        return 'light-blue-shadow'
      }
      return ''
    }
  },
  methods: {
    click() {
      this.$emit('click')
    }
  }
}
</script>

<style>
  .deep-blue-shadow {
    box-shadow: 0 0 3px #023466;
  }
  .light-blue-shadow {
    box-shadow: 0 0 3px #7fa5d0;
  }
</style>