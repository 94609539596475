<template>
  <div class="order-card">
    <div class="top">
      <span>订单号：{{data.order_number}}</span>
      <span class="col-red mr5">订单详情></span>
    </div>
    <div class="gift-card-wrap" v-for="(item, index) in data.gitf_list" :key="index">
      <gift-card :data="item"></gift-card>
    </div>
    <div class="card-footer">
      <span>共<i class="ml5 mr5">{{data.gitf_num}}</i>件礼品</span>
      <span class="ml15">合计额度：<i class="col-red">{{$utils.formatNum(data.total_quota)}}</i></span>
    </div>
  </div>
</template>

<script>
import GiftCard from './GiftCard.vue'
export default {
  components: {
    GiftCard
  },
  props: {
    data: Object,
  },
  computed: {
    // allLimit() {
    //   let result =  this.data.gitf_list.reduce((total, cur, index) => {
    //     if(index == 0) {
    //       return total.gitf_total_quota + cur.gitf_total_quota 
    //     }
    //     return total + cur.gitf_total_quota 
    //   }, {gitf_total_quota: 0})
    //   return result
    // } 
  },
  data() {
    return {
    }
  }
}
</script>

<style lang="scss">
.order-card {
  padding: 0 15px;
  border-radius: 15px;
  background-color: #fff;
  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 24px;
    height: 70px;
    border-bottom: 1px solid #d9d9d9;
  }
  .gift-card-wrap {
    border-bottom: 1px solid #d9d9d9;
  }
  .card-footer {
    padding-right: 20px;
    height: 80px;
    line-height: 80px;
    font-size: 24px;
    color: #666;
    text-align: right;
    letter-spacing: 1px;
    i {
      font-style: inherit;
    }
  }
}
</style>