<template>
  <div class="address-list-page">
    <van-radio-group v-model="chooseAddressId" icon-size="16">
      <van-swipe-cell v-for="item in addressList" :key="item.id">
        <div class="address-card" @click.stop="chooseAddress(item)" v-if="item.id>0">
          <van-radio :name="item.id" class="mr15" v-if="showCheck" @click.stop=""></van-radio>
          <div class="left">
            <div class="receiver-box">
              <span class="receiver-title">收货人：</span>
              <div class="receiver-text">
                <span class="name">{{ item.consignee_name }}</span>
                <span class="phone">{{ item.consignee_mobile }}</span>
                <span class="default ml20" v-if="item.default == 1">默认</span>
              </div>
            </div>
            <div class="address-box">
              <span class="address-title">收货地址：</span>
              <span class="address-text">{{ item.full_specific_address }}</span>
            </div>
          </div>
          <van-icon name="edit" color="#666666" size="20" @click="editAddress(item)" />
        </div>
        <template #right>
          <van-button square type="danger" text="删除" @click="delAddress(item.id)" />
        </template>
      </van-swipe-cell>
    </van-radio-group>

    <div class="add-btn" @click="addAddress()">
      +新建收货地址
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showCheck: false,
      chooseAddressId: "",
      addressList: [],
    };
  },
  methods: {
    async getAddressList() {
      let res = await this.axios.get("client/v1.Member_Address/addressList");
      this.addressList = res.data;	
    },
    async delAddress(id) {
      this.$dialog.confirm({
        title: '删除地址',
        message: '是否删除该地址？'
      }).then(async () => {
        await this.axios.post('client/v1.Member_Address/deleteAddress', {address_id: id})
        await this.getAddressList()
        if(this.addressList.length === 0) {
          this.$store.commit('defaultAddressMut', {})
        }
      })
    },
    chooseAddress(item) {
      if(this.$route.query.cho) {
        this.$store.commit('defaultAddressMut', {
          id: item.id,
          name: item.consignee_name,
          phone: item.consignee_mobile,
          province: item.province_name,
          city: item.city_name,
          district: item.district_name,
          detailAddress: item.brief_address,
          fullAddress: item.full_specific_address
        })
        this.chooseAddressId = item.id
        this.$router.go(-1)
      }
    },
    editAddress(item) {
      let params = {
        id: item.id,
        name: item.consignee_name,
        phone: item.consignee_mobile, 
        detailAddress: item.brief_address,
        isDefault: item.default ? true : false,
        province: item.province_name,
        city: item.city_name,
        district: item.district_name
      }
      this.$router.push({name: 'EditAddress', params})
    },
	addAddress() {
		let params = {
			id: -1
		}		
		this.$router.push({name: 'EditAddress', params})
	}
  },
  created() {
    if(this.$route.query.cho){
      this.showCheck = true
      this.chooseAddressId = +this.$route.query.id
    } 
    this.getAddressList();
  },
};
</script>

<style lang="scss">
.address-list-page {
  padding-top: 25px;
  padding-bottom: env(safe-area-inset-bottom);
  .van-button {
    height: 100%;
  }
  .address-card {
    display: flex;
    align-items: center;
    margin-bottom: 1px;
    padding: 20px;
    min-height: 160px;
    box-sizing: border-box;
    background-color: #fff;
    font-size: 24px;
    .left {
      width: 600px;
      margin-right: auto;
      .receiver-title,
      .address-title {
        width: 130px;
        text-align-last: justify;
      }
      .receiver-title {
        font-size: 28px;
      }
      .receiver-box {
        display: flex;
        align-items: center;
        .receiver-text {
          display: flex;
          align-items: baseline;
          .name {
            margin-right: 20px;
            font-size: 28px;
          }
          .phone {
            font-size: 24px;
          }
        }

        .default {
          padding: 2px 12px;
          background-color: #7fa5d0;
          border-radius: 22px;
          color: #fff;
          font-size: 20px;
        }
      }
      .address-box {
        display: flex;
        align-items: flex-start;
        margin-top: 8px;
        .address-title {
          flex-shrink: 0;
        }
        .address-text {
          text-align: left;
        }
      }
    }
  }
  .add-btn {
    position: absolute;
    margin: 0 auto;
    bottom: 30px;
    width: 600px;
    height: 65px;
    left: 50%;
    transform: translateX(-50%);
    color: $deep-blue-color;
    border: 1px solid $deep-blue-color;
    border-radius: 40px;
    line-height: 65px;
  }
}
</style>
