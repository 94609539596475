<template>
  <div class="submit-page">
    <div class="address-box" @click="$router.push({path: 'addressList', query: {cho: true, id: defaultAddressInfo.id}})">
      <van-icon name="location" color="#396594" size="20" style="flex-shrink: 0" />
      <div class="address-info" v-if="defaultAddressInfo.id">
        <div class="people">{{`${defaultAddressInfo.name} ${defaultAddressInfo.phone}` }}</div>
        <div class="address">{{defaultAddressInfo.fullAddress}}</div>
      </div>
      <span v-else>请选择地址</span>
      <van-icon name="arrow" color="#a6a6a6" size="20" style="flex-shrink: 0" />
    </div>

    <div class="gift-list">
      <div class="item" v-for="(item, index) in submitPageData.giftInfoList" :key="index">
        <gift-card :data="item" borderRadius></gift-card>
      </div>
    </div>

    <div class="delivery">
      <span>配送方式</span>
      <span class="fz24">快递免邮</span>
    </div>

    <div class="statistics">
      <div class="cell">
        <span>礼品数量</span>
        <span>{{allGiftNum}}</span>
      </div>
      <div class="cell">
        <span>礼品总额数</span>
        <span class="col-red">{{$utils.formatNum(allLimit)}}</span>
      </div>
      <div class="cell">
        <span>运费</span>
        <span>0</span>
      </div>
    </div>

    <div class="submit-box">
      <k-button className="submit-btn" @click="show = true">提交</k-button>
    </div>

    <!-- <p class="col-red mt15">
          兑换券使用说明<br />
          1、本券每次限一次性全额使用。<br />
          2、本券不兑现金，不设找零，不可重复使用，不限制使用张数。
        </p> -->
    <van-dialog v-model="show" title="是否确认兑换此礼品？" show-cancel-button @confirm="submitOrder">
      <div class="dialog-content">
        <p>兑换后将扣除相应兑换券（订单一经确认提交将无法取消或更改）</p>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import GiftCard from '../components/GiftCard.vue'
import {mapState, mapActions} from 'vuex'
export default {
  components: {
    GiftCard,
  },
  computed: {
    ...mapState(['submitPageData', 'defaultAddressInfo']),
    allGiftNum() {
      let num = 0
      this.submitPageData.giftInfoList.forEach(v => num += v.num)
      return num
    },
    allLimit() {
      let count  = 0
      this.submitPageData.giftInfoList.forEach(v => count += v.limit * v.num)
      return count
    }
  },
  data() {
    return {
      show: false,
      result: [],
      couponList: [],
    }
  },
  methods: {
    ...mapActions(['defaultAddressAct']),
    async submitOrder() {
        let params = this.makeParams()
        let res = await this.axios.post('client/v1.Member_Order/mergeOrder', params)
        if(!res) return
		if(res.data==0){
			this.$dialog.alert({
			  title: '异常提示',
			  message: '非常抱歉，'+res.msg+"，请联系客服~",
			})
			return;
		}
        this.$router.replace({name: 'Result', params: res.data})
    },
    makeParams() {
      let obj = {
        address_id: this.defaultAddressInfo.id,
        order_goods_id_str: this.submitPageData.cartIdAry.join(',')
      }
      return obj
    },
  },
  created() {
    this.defaultAddressAct()
    if(this.submitPageData.cartIdAry.length > 1 && this.$route.params.from === 'Bag') {
      this.$dialog.alert({
        title: '拆单提示',
        message: '由于您订单有一单多品，故有可能会拆单发货，烦请留意订单内快递单号，感谢您的理解和支持！'
      })
    }
  }
}
</script>

<style lang="scss">
.submit-page {
  padding: 20px 20px 0;
  .dialog-content {
    padding: 25px 30px 15px;
    font-size: 26px;
    color: #666;
    text-align: left;
    line-height: 40px;
  }
  .address-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 170px;
    background-color: #fff;
    border-radius: 15px;
    padding: 0 10px;
    .address-info {
      margin: 0 40px 0 20px ;
      flex: 1;
      .people {
        text-align: left;
        color: #333;
        font-size: 24px;
      }
      .address {
        margin-top: 10px;
        line-height: 32px;
        font-size: 28px;
        color: #1a1a1a;
        text-align: left;
      }
    }
  }
  .gift-list {
    padding-top: 20px;
    .item {
      margin-bottom: 1px;
    }
  }
  .delivery {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;
    padding: 0 20px;
    height: 85px;
    background-color: #fff;
    border-radius: 15px;
  }
  .statistics {
    padding: 20px;
    border-radius: 15px;
    background-color: #fff;
    .cell {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 50px;
      font-size: 24px;
    }
  }
  .select-coupon {
    margin-top: 20px;
    padding: 0 20px;
    border-radius: 15px;
    background-color: #fff;
    .title {
      font-weight: 900;
      height: 80px;
      line-height: 80px;
      text-align: left;
    }
    .item {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: 20px;
    }
    .coupon {
      display: flex;
      align-items: center;
      margin-right: 12px;
      width: 610px;
      height: 60px;
      box-sizing: border-box;
      background-image: url('../assets/images/coupon-border-bg.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      .name {
        width: 175px;
        font-size: 28px;
        text-align: center;
      }
      .right-box {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        padding: 0 15px 0 6px;
        color: #fb9066;
        flex: 1;
        font-size: 20px;
        em {
          margin-right: 2px;
          font-style: inherit;
          font-size: 30px;
        }
      }
    }
    .select-coupon-footer {
      text-align: right;
      border-top: 1px solid #d9d9d9;
      height: 80px;
      line-height: 80px;
      padding: 0 20px;
      font-size: 24px;
    }
  }
  .submit-box {
    padding: 50px 0;
    .submit-btn {
      margin: 0 auto;
      width: 600px;
      height: 65px;
      border-radius: 40px;
    }
  }
}
</style>