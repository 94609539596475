<template>
	<div>
  <div @click="contactUs">
    <img class="contact-us" src="../assets/images/cantact2.png" />
  </div>
  <div @click="backTop"
	v-show="scrollType">
	<img class="backTop" src="../assets/images/backtop.png" />
	</div>
</div>
</template>

<script>
export default {
	name: 'backTop',
	data() {
	   return {
	        scrollType: false, // 控制按钮显示和隐藏
	        timerId: null,
	        scrollTop: 0
	      }
	    },
		 // 组件创建监听scroll此方法
		  mounted () {
		    window.addEventListener('scroll', this.handleScoll, true)
		  },
		  // 组件销毁移除监听
		  destroyed () {
		    window.removeEventListener('scroll', this.handleScoll)
		    window.clearInterval(this.timerId)
		  },
  methods: {
    contactUs() {
      //window.open('https://www.tidio.com/talk/rbw6l1qfwf9mmffmjjingh66lddbnt8s')
	  window.open('/h5/chatlink.html')
    },
	 backTop () {
	      this.timerId = setInterval(() => {
	        // 给返回增加动画效果
	        const upSpeed = Math.floor(-this.scrollTop / 5)
	        document.documentElement.scrollTop = this.scrollTop + upSpeed
	        if (this.scrollTop === 0) {
	          clearInterval(this.timerId)
	        }
	      }, 30)
	    },
	    handleScoll () {
	      // 当滚动的距离大于700 时出现该按钮
	      this.scrollTop = window.pageYOffset
	      if (window.pageYOffset / 100 > 3) {
	        this.scrollType = true
	      } else {
	        this.scrollType = false
	      }
	    }
  }
};
</script>


<style>
.contact-us {
  position: fixed;
  right: 20px;
  bottom: 15vh;
  width: 60px;
  height: 60px;
  border-radius: 100%;
  background-color: #fff;
}
.backTop {
  position: fixed;
  bottom: 10vh;
  right: 20px;
  width: 60px;
  height: 60px;
  border-radius: 100%;
  z-index: 999;
  border: none;
  background-color: #fff;
}
</style> 