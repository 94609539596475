<template>
  <div>
    <van-tabbar v-model="active" route v-if="show" fixed>
      <van-tabbar-item :to="item.path" v-for="(item, index) in tabs" :key="index">
        <template #icon>
          <img :src="active === index ? item.icon.active : item.icon.inactive "/>
        </template>
        <span :class="[active === index ? 'col-deep-blue' : 'col-inactive']">{{item.text}}</span>
      </van-tabbar-item>
    </van-tabbar>   
    <div class="placeholder" v-if="show"></div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      show: true,
      active: 0,
      tabs: [
      // {
      //   path: '/MyCoupon',
      //   text: '我的兑换券',
      //   active: true,
      //   icon: {
      //     active: require('../assets/images/coupon--active.png'),
      //     inactive: require('../assets/images/coupon--inactive.png')
      //   }
      // }, 
	  {
        path: '/Gift',
        text: '礼品兑换',
        active: false,
        icon: {
          active: require('../assets/images/gift--active.png'),
          inactive: require('../assets/images/gift--inactive.png')
        }
      }, {
        path: '/Bag',
        text: '兑换袋',
        active: false,
        icon: {
          active: require('../assets/images/bag--active.png'),
          inactive: require('../assets/images/bag--inactive.png')
        }
      }, {
        path: '/My',
        text: '我的',
        active: false,
        icon: {
          active: require('../assets/images/my--active.png'),
          inactive: require('../assets/images/my--inactive.png')
        }
      }]
    }
  },
  watch: {
    '$route': function(to) {
      let paths = this.tabs.map(v => v.path)
      let index = paths.indexOf(to.path)
      if(index !== -1) {
        this.active = index
        this.show = true
      } else {
        setTimeout(() => {
          this.show = false
        }, 200)
      }
    }
  },
  created() {
    let paths = this.tabs.map(v => v.path)
    let index = paths.indexOf(this.$route.path)
    if(index !== -1) {
      this.active = index
      this.show = true
    } else {
      this.show = false
    }
  }
}
</script>

<style>
  .placeholder {
    box-sizing: content-box;
    height: 100px;
    padding-bottom: env(safe-area-inset-bottom);
  }
  .col-inactive {
    color: #ced9e5;
  }
</style>