<template>
  <div class="login-page">
    <img class="logo" src="../assets/images/logo.png" />
    <p class="mt35 fz32">欢迎登录</p>
    <p class="col-deep-blue fz28">礼品兑换商城</p>
    <div class="form">
      <div class="form-item">
        <label for="phone">登录账号</label>
        <div class="input-box"><input v-model="username" id="username" placeholder="请输入账号" type="text" min="" :maxlength="50" /></div>
      </div>
      <div class="form-item">
        <label for="code">登录密码</label>
        <div class="input-box">
          <input v-model="pass" id="pass" placeholder="请输入登录密码" type="password"	:maxlength="16" />         
        </div>
      </div>
    </div>
    <div>
      <k-button className="btn gray-shadow" :loading="loading" @click="login">登录 →</k-button>
    </div>

    <cantact-us />
  </div>
</template>

<script>
import CantactUs from '../components/CantactUs.vue'
import {mapState} from 'vuex'
export default {
  components: {
    CantactUs
  },
  computed: {
    ...mapState(['token']),
  },
  data() {
    return {
      username: '',
      pass: '',
      wait: false,
      loading: false,
    }
  },
  methods: {
        async login() {
      this.loading = true
      let res = await this.axios.post('client/v1.Member/Login',{username: this.username, pass: this.pass})
      if(!res) {
        this.loading = false
        return
      }
      this.$store.commit('login', res.data.jwt)
      this.loading = true
      this.$router.replace('Gift')
    }
  },
  created() {
    if(this.token) {
      this.$router.replace('Gift')
    }
  }
}
</script>

<style lang="scss">
  .login-page {
    .logo {
      margin-top: 90px;
      width: 461px;
      height: 163px;
    }

    

    .form {
      margin-top: 75px;
      .form-item {
        text-align: left;
        margin-bottom: 45px;
        label {
            margin-left: 100px;
          }
        .input-box {
          margin: auto;
          margin-top: 15px;
          display: flex;
          width: 600px;
          height: 90px;
          box-shadow: 0 0 15px 5px #d2dbe5;
          overflow: hidden;
          border-radius: 12px;
          input {
            border: 0;
            outline: none;
            flex: 1;
            width: 200px;
            height: 100%;
            padding: 0 25px;
          }
          .code-btn {
            display: inline-block;
            width: 200px;
            background-color: #fff;
            font-size: 28px;
            color: $deep-blue-color;
            height: 100%;
          }
        }
      }
    }

    .btn {
      margin-top: 60px;
      font-size: 28px;
      width: 400px;
      border-radius: 20px;
      height: 70px;
    }
}
  
</style>