<template>
  <div id="app">
    <NavBar />
    <div class="main">
      <!-- <transition name="fade" mode="out-in"> -->
        <keep-alive include="MyCoupon,Gift,myOrder,My">
           <router-view />
        </keep-alive>
      <!-- </transition> -->
    </div>
    <TabBar />
  </div>
</template>

<script>
import NavBar from "./components/NavBar"
import TabBar from "./components/TabBar"
export default {
  components: {
    NavBar,
    TabBar,
  }
};
</script>

<style lang="scss">
@import url("./assets/scss/reset.scss");

.fade-enter-active,

.fade-leave-active {

  transition: opacity 0.2s ease;

}

 

 

.fade-enter-from,

.fade-leave-to {

  opacity: 0;

}

#app {
  // "PingFangSC-Bold"
  font-family: "PingFangSC-Medium", Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #333;
  font-size: 28px;
}
body {
  background-color: $bg-gray-color;
}

@for $i from 12 through 42 {
  .fz#{$i} {
    font-size: $i + px;
  }
}

@for $i from 1 through 50 {
  .m#{$i} {
    margin: $i + px;
  }
  .ml#{$i} {
    margin-left: $i + px;
  }
  .mr#{$i} {
    margin-right: $i + px;
  }
  .mt#{$i} {
    margin-top: $i + px;
  }
  .mb#{$i} {
    margin-bottom: $i + px;
  }
  .p#{$i} {
    padding: $i + px;
  }
  .pl#{$i} {
    padding-left: $i + px;
  }
  .pr#{$i} {
    padding-right: $i + px;
  }
  .pt#{$i} {
    padding-top: $i + px;
  }
  .pb#{$i} {
    padding-bottom: $i + px;
  }
}

.col-deep-blue {
  color: $deep-blue-color;
}

.gray-shadow {
  box-shadow: 0 0 15px 5px #d2dbe5;
}

.col-red {
  color: $col-red;
}
.col333 {
  color: #333;
}
.col666 {
  color: #666;
}
.col999 {
  color: #999;
}
.line1 {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.line2 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 2;
}
</style>
