<template>
  <div class="my-page">
    <div class="my-info-box">
      <img class="avatar" :src="sysConfig.platform_logo" alt="头像" />
      <div class="info">
        <p class="name">{{'您好~'}}</p>
        <p class="phone">{{myInfo.phone}}</p>
      </div>
      <div v-if="myInfo.phone" class="login-out-btn" @click="login">退出登录</div>
      <div v-else class="login-out-btn" @click="$router.push('Login')">请登录</div>
    </div>
    <div class="order-box">
      <van-cell title="我的订单" is-link to="MyOrder?active=0" />
      <van-grid :border="false" column-num="3">
        <van-grid-item :icon="require('../assets/images/wait-send.png')" text="待发货" :badge="myInfo.wait_deliver_num || ''" @click="$router.push({path: '/MyOrder', query: {active: 0}})" />
        <van-grid-item :icon="require('../assets/images/wait-receive.png')" text="待收货" :badge="myInfo.already_deliver_num || ''" @click="$router.push({path: '/MyOrder', query: {active: 1}})" />
        <van-grid-item :icon="require('../assets/images/have-been-take.png')" text="已收货" :badge="myInfo.already_confirm_num || ''" @click="$router.push({path: '/MyOrder', query: {active: 2}})" />
      </van-grid>
    </div>

    <div class="my-cell-group">
       <van-cell-group>
        <van-cell title="我的收货地址" is-link to="AddressList" icon="logistics" />
        <van-cell title="我的兑换券" is-link to="MyCoupon" class="CouponLinks" icon="coupon-o" color="red" />
		<van-cell title="活动条款&细则" is-link to="Clause" icon="notes-o"/>
		<van-cell title="有奖问卷" is-link url="/h5/wj.html" icon="records"/>
      </van-cell-group>
    </div>
   
</div>
	
</template>

<script>
import {mapActions, mapState} from 'vuex'
export default {
  name: 'My',
  computed: {
    ...mapState(['sysConfig'])
  },
  data() {
    return {
      myInfo: {},
    }
  },
  methods: {
    ...mapActions(['sysConfigAct']),
    async getMyInfo() {
      let res = await this.axios.get('client/v1.Member/getMemberInfo')
      this.myInfo = res.data
    },
    login() {
      this.$dialog.confirm({
        title: '退出登录',
        message: '确定退出当前账号吗？'
      }).then(() => {
        this.$store.commit('loginOutMut')
      })
    }
  },
  activated(){
    this.sysConfigAct()
    this.getMyInfo()
  }
}
</script>

<style lang="scss">
.my-page {
  .van-cell::after {
    border-bottom-color: #d9d9d9;
  }
  .van-cell__title {
    font-size: 28px;
    color: #1a1a1a;
  }
  .van-grid-item__text {
    font-size: 24px;
  }
  .my-info-box {
    display: flex;
    align-items: center;
    padding: 0 20px;
    height: 160px;
    background-color: #023466;
    color: #fff;
    .avatar {
      width: 90px;
      height: 88px;
      flex-shrink: 0;
      }
      .info {
        margin-left: 20px;
        text-align: left;
        flex: 1;
        .name {
          width: 420px;
          margin-bottom: 5px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .login-out-btn {
        flex-shrink: 0;
        width: 140px;
        height: 62px;
        line-height: 62px;
        border: 1px solid #fff;
        border-radius: 30px;
      }
  }
  .order-box {
    margin: 25px 20px;
    border-radius: 15px;
    overflow: hidden;
    text-align: left;
    background-color: #fff;
  }
  .my-cell-group {
    margin: 25px 20px;
    text-align: left;
    border-radius: 15px;
    overflow: hidden;
	.CouponLinks span{color: #023466;font-weight: bold;}
  }
}
</style>