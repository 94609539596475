<template>
  <div>
   <van-nav-bar
   v-if="showBackIcon"
      fixed
      placeholder
      :left-arrow="showBackIcon"
      :title="title"
      @click-left="onClickLeft"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      showBackIcon: false,
      title: '保乐力加中国礼品兑换平台',
      tabPaths: ['/Bag', '/Gift', '/Login', '/My']
    }
  },
  watch: {
    '$route': function(to) {
      if(window.history.length === 0 || this.tabPaths.includes(to.path)) {
        this.showBackIcon = false
      } else {
        this.showBackIcon = true
      }
      this.title = to.meta.name || '保乐力加中国礼品兑换平台'
    }
  },
  created() {
    this.title = this.$route.meta.name
    if(window.history.length === 0 || this.tabPaths.includes(this.$route.path)) {
      this.showBackIcon = false
    } else {	 
      this.showBackIcon = true
    }
	
  },
  methods: {
    onClickLeft(){
      this.$router.go(-1)
    }
  }
}
</script>

<style>

</style>