<template>
  <div>
    <slot />
  </div>
</template>

<script>
export default {
  data() {
    return {
    }
  },
  methods: {
    startCount() {
      if(this.timer != null) {
        return
      }
      this._startCountDown()
      this.$emit('startCount')
    },
    _startCountDown() {
      this.countDown = 60
      this.timer = setInterval(() => {
        if (this.countDown <= 0) {
          this._stopCountDown()
          return
        }
        this.countDown--
        this.$emit('count', this.countDown)
      }, 1000)
    },
    _stopCountDown() {
      this.countDown = 0
      clearInterval(this.timer)
      this.timer = null
      this.$emit('countStop')
    }
  }
}
</script>

<style>

</style>