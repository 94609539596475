import Vue from 'vue'
import store from '../store/index'
import VueRouter from 'vue-router'
import MyCoupon from '../views/MyCoupon.vue'
import Clause from '../views/Clause.vue'
import Gift from '../views/Gift.vue'
import Bag from '../views/Bag'
import My from '../views/My'
import Login from '../views/Login'
import LoginU from '../views/LoginU'
import GiftDetail from '../views/GiftDetail.vue'
import SubmitOrder from '../views/SubmitOrder.vue'
import Result from '../views/Result.vue'
import AddressList from '../views/AddressList.vue'
import MyOrder from '../views/MyOrder.vue'
import OrderDetail from '../views/OrderDetail.vue'
import EditAddress from '../views/EditAddress.vue'
import AddToPool from '../views/AddToPool.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/Login',
  },
  {
    path: '/LoginU',
    name: 'LoginU',
    component: LoginU,
    meta: {
      name: '体验账号登录'
    }
  },
  {
    path: '/MyCoupon',
    name: 'MyCoupon',
    component: MyCoupon,
    meta: {
      name: '我的兑换券'
    }
  },
  {
    path: '/Gift',
    name: 'Gift',
    component: Gift,
    meta: {
      name: '礼品兑换'
    }
  },
  {
    path: '/Bag',
    name: 'Bag',
    component: Bag,
    meta: {
      name: '兑换袋'
    }
  },
  {
    path: '/My',
    name: 'My',
    component: My,
    meta: {
      name: '我的'
    }
  },
  {
    path: '/Login',
    name: 'Login',
    component: Login,
    meta: {
      name: '登录'
    },
    beforeEnter: (to,from, next) => {
      if(store.state.token) {
        next('Gift')
      } else {
        next()
      }
    }
  },
  {
    path: '/Detail',
    name: 'Detail',
    component: GiftDetail,
    meta: {
      name: '礼品兑换详情'
    }
  },{
    path: '/Submit',
    name: 'Submit',
    component: SubmitOrder,
    meta: {
      name: '订单结算'
    }
  },{
    path: '/Result',
    name: 'Result',
    component: Result,
    meta: {
      name: '订单确认'
    }
  },{
    path: '/AddressList',
    name: 'AddressList',
    component: AddressList,
    meta: {
      name: '地址列表'
    }
  },{
    path: '/MyOrder',
    name: 'MyOrder',
    component: MyOrder,
    meta: {
      name: '我的订单'
    }
  },{
    path: '/OrderDetail',
    name: 'OrderDetail',
    component: OrderDetail,
    meta: {
      name: '订单详情'
    }
  },{
    path: '/EditAddress',
    name: 'EditAddress',
    component: EditAddress,
    meta: {
      name: '收货地址'
    }
  },{
    path: '/AddToPool',
    name: 'AddToPool',
    component: AddToPool,
    meta: {
      name: '添加确认'
    }
  },{
    path: '*',
    redirect: '/',
  },
  {
    path: '/Clause',
    name: 'Clause',
    component: Clause,
    meta: {
      name: '活动条款&细则'
    }
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = new VueRouter({
  routes,
  scrollBehavior (to, from, savedPosition) {
    return new Promise((resolve) => {
      if ((to.name === 'Gift' || to.name === 'MyOrder') && savedPosition) {
        resolve(savedPosition)
      } else {
        resolve({x:0, y: 0})
      }
      
    })
  }
})

export default router
