<template>
  <div class="bag-page">
    <div class="card-list" > 
      <van-swipe-cell v-for="(item, index) in giftList" :key="index" data-intro='左滑删除礼品' data-step="1" >
        <div class="card" >
          <!-- <van-checkbox class="checkbox" :name="item.id" checked-color="#1a497a" ref="checkboxes"></van-checkbox> -->
          <img class="gift-img ml15" :src="item.gitf_thumbnail_url" alt="礼品图片" />
          <div class="info" >
            <p class="title line2">{{item.gitf_name}}</p>
            <p class="line1 sub-title" v-if="item.activity_name_str">{{item.activity_name_str}}</p>
            <div class="bottom" @click.stop="">
              <span>{{$utils.formatNum(item.gitf_total_quota)}}</span>
              <span class="limit">额度</span>
              <span style="font-size: 14px; color: #666; margin-right: 10px">x{{item.gitf_number}}</span>
              <!-- <van-stepper async-change @change="onChange($event, index)" :value="item.number" min="1" max="99" :input-width="$utils.px2vw(60)" :size="$utils.px2vw(48)" /> -->
            </div>
          </div>
        </div>
        <template #right>
          <van-button square text="删除" type="danger" style="height: 100%" @click="delGift(index)" />
        </template>
      </van-swipe-cell>
    </div>
    <van-empty description="兑换袋空空如也~" v-if="giftList.length === 0" />
    <!-- 结算 -->
    <div class="count-footer">
      <div class="count">
        <div>合计：<span class="limit-count"><em>{{allChoLimit}}</em>额度</span></div>
        <k-button className="count-btn" @click="submitOrder" data-intro='提交订单才能为您发货~' data-step="2" data-position="right">提交订单</k-button>
      </div>
       <div class="count-placeholder"></div>
    </div>
	<cantact-us />
  </div>
</template>

<script>
import KButton from '../components/KButton'
import CantactUs from '../components/CantactUs.vue'
export default {
  name: "Bag",
  components: {
    KButton,
	CantactUs
  },
  computed: {
    allChoLimit() {
      let count = 0
      this.giftList.forEach(v => {
        count += v.gitf_total_quota * v.gitf_number
      })
      return this.$utils.formatNum(count) || 0
    },
    giftIds() {
      return this.giftList.map(v => v.id)
    }
  },
  data() {
    return {
      giftList: []
    }
  },
  methods: {
    async getBagList() {
      let res = await this.axios.get('client/v1.Member_Order/getDuiHuanList')
      this.giftList = res.data	  
	  this.$nextTick(function(){
					if(this.isIntro()&&this.giftList.length>0){
	                 this.$intro()
	                       .setOptions({nextLabel:"下一步",prevLabel:"上一步 ",doneLabel:"我知道了"})
	                       .start();
					this.$utils.savelocalStorage('bagIntro',JSON.stringify({status:0}))
					}
	              })
    },
    submitOrder() {
      let giftInfoList = []
      this.giftList.forEach(v => {
        giftInfoList.push({
          id: v.id,
          num: v.gitf_number,
          img: v.gitf_thumbnail_url,
          name: v.gitf_name,
          limit: v.gitf_total_quota,
          activeName: v.activity_name_str
        })
      })
      this.$store.commit('submitPageDataMut', {
        giftInfoList,
        cartIdAry: this.giftIds
      })
      this.$router.push({name: 'Submit', params: {from: 'Bag'}})
    },
    toggle(index) {
      this.$refs.checkboxes[index].toggle();
    },
    async delGift(index) {
      this.$dialog.confirm({
        title: '删除礼品',
        message: '是否将该礼品从兑换袋中移出？'
      }).then(async () => {
        await this.axios.post('client/v1.member_order/delExchangeGitf', {order_goods_id: `${this.giftList[index].id}`})
        this.getBagList()
      }) 
    },
	isIntro(){
		let res=this.$utils.readlocalStorage('bagIntro');
		return res==null;
	}
  },
  created() {
    this.getBagList()
	
  }
}
</script>

<style lang="scss">
.bag-page {
  .card-list {
    padding: 20px;
    padding-bottom: 0;
    .card {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      background-color: #fff;
      border-radius: 15px;
      padding: 25px 10px;
      height: 220px;
      .checkbox {
        flex-shrink: 0;
      }
      .gift-img {
        width: 145px;
        height: 145px;
        flex-shrink: 0;
      }
      .info {
        display: flex;
        flex-direction: column;
        margin-left: 20px;
        align-self: stretch;
        flex: 1;
        .title {
          text-align: left;
          font-size: 28px;
          color: #1a1a1a;
          line-height: 36px;
        }
        .sub-title {
          margin-top: 3px;
          font-size: 20px;
          color: #999;
          text-align: left;
          width: 430px;
        }
        .bottom {
          margin-top: auto;
          display: flex;
          align-items: center;
          color: $col-red;
          font-size: 36px;
          .limit {
            position: relative;
            top: 5px;
            font-size: 20px;
            margin: 0 auto 0 5px;
          }
          .van-stepper {
            position: relative;
            border: 1px solid #d9d9d9;
            border-radius: 10px;
            &::before,
            &::after {
              content: '';
              position: absolute;
              z-index: 2;
              top: 12px;
              bottom: 12px;
              width: 1px;
              background-color: #d9d9d9;
            }
            &::before{
              left: 53px;
            }
            &::after {
              left: 123px;
            }
            .van-stepper__minus,
            .van-stepper__plus,
            .van-stepper__minus--disabled, 
            .van-stepper__plus--disabled,
            .van-stepper__input {
              background-color: #fff;
            }
            .van-stepper__minus::before,
            .van-stepper__plus::before{
              width: 35%;
            }
            .van-stepper__minus::after,
            .van-stepper__plus::after {
              height: 33%;
            }
            .van-stepper__input {
              position: relative;  
            }
          }
        }
      }
    }
  }
  .count {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: fixed;
    box-sizing: content-box;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: env(safe-area-inset-bottom);
    bottom: 100px;
    left: 0;
    right: 0;
    background-color: #fff;
    min-height: 100px;
    font-size: 24px;
    .limit-count {
      color: $col-red;
      em {
        font-size: 36px;
        font-style: initial;
      }
    }
    .check-box {
      margin-left: 10px;
      margin-right: auto;
    }
    .count-btn {
      margin-left: 20px;
      width: 180px;
      height: 70px;
      font-size: 28px;
      border-radius: 40px;
    }
  }
  .count-placeholder {
      box-sizing: content-box;
      height: 100px;
      
    }
}
</style>