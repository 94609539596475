<template>
  <div class="card" :class="[borderRadius? 'border-radius15' : '']">
    <img class="gift-img" :src="data.gitf_thumbnail_url || data.img" alt="商品图片" />
    <div class="info">
      <p class="title line2">{{ data.gitf_name || data.name }}</p>
      <p class="sub-title line1">{{ data.small_title }}</p>
      <div class="bottom" @click.stop="">
        <span>{{ data.gitf_single_quota || $utils.formatNum(data.limit) }}</span>
        <span class="limit">额度</span>
        <span class="num">x{{ data.gitf_number || data.num }}</span>
        <span class="state">{{data.status_name}}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    borderRadius: Boolean
  },
};
</script>

<style lang="scss" scoped>

.card {
  display: flex;
  align-items: center;
  margin-bottom: 1px;
  background-color: #fff;
  padding: 25px 10px;
  height: 200px;
  &.border-radius15 {
    border-radius: 15px;
  }
  .gift-img {
    width: 145px;
    height: 145px;
    flex-shrink: 0;
  }
  .info {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    align-self: stretch;
    flex: 1;
    .title {
      text-align: left;
      font-size: 28px;
      color: #1a1a1a;
      line-height: 36px;
    }
    .sub-title {
      margin-top: 3px;
      font-size: 20px;
      color: #999;
      text-align: left;
      width: 430px;
    }
    .bottom {
      display: flex;
      align-items: baseline;
      margin-top: auto;
      color: $col-red;
      padding-right: 10px;
      font-size: 36px;
      .limit {
        font-size: 20px;
        margin: 0 10px 0 5px;
      }
      .num {
        color: #333;
        font-size: 24px;
        margin-right: auto;
      }
      .state {
        font-size: 28px;
      }
    }
  }
}
</style>
