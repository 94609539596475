<template>
  <div class="coupon-list">
    <div
      class="item"
      :class="getCouponBackgroundImageClassName(item)"
      v-for="item in couponList"
      :key="item.id"
      @click="couponItemClickHandle(item)"
    >
      <div class="left-box">
        <div class="first-col">
          <span style="max-width: 2.5em">剩余额度</span
          ><span class="num">{{ item.surplus_quota }}</span>
        </div>
        <div class="second-col">
          <p class="fz25">
            兑换券面值: <span class="ml5">{{ item.quota_str }}</span>
          </p>
          <p class="fz20">{{ DateTextFormat(item.status, item.time_str) }}</p>
          <div style="display: flex; align-items: center">
            <p class="fz20 col-red mt5" v-if="item.surplus_days">
              {{ `(仅剩${item.surplus_days}天)` }}
            </p>
          </div>
        </div>
        <p class="line1 pl30 fz22 active-name">{{ item.activity_name }}</p>
      </div>
    </div>
    <van-empty v-if="couponList.length === 0" description="暂无兑换券" />

    <div class="active-info-box">
      <div class="active-title">活动条款&细则</div>
      <div v-html="sysConfig.activity_explain" class="fz24"></div>
    </div>

    
  </div>
</template>

 <script>
import { mapActions, mapState } from "vuex";
export default {
  name: "MyCoupon",
  data() {
    return {
      couponList: [],
    };
  },
  computed: {
    ...mapState(["sysConfig"]),
    canShowNotify() {
      return this.sysConfig?.exchange_notice_switch === 1;
    },
  },
  methods: {
    ...mapActions(["sysConfigAct"]),
    getCouponBackgroundImageClassName(item) {
      // 3:已失效，4:未生效，5:已使用，7:未激活 9:可使用
      switch (item.status) {
        case 3:
          return "failed";
        case 4:
          return "invalid";
        case 5:
          return "used";
        case 7:
          return "inactive";
        case 9:
          return "active";
        default:
          return "failed";
      }
    },
    couponItemClickHandle(item) {
      if (item.status === 9 || item.status === 7) {
        this.$router.push({
          path: "Gift",
          query: { activeId: item.activity_id, couponId: item.id },
        });
      }
    },
    DateTextFormat(status, timeStr) {
      // 3:已失效，4:未生效，5:已使用，7:未激活 9:可使用
      if (status === 3) {
        return `失效于${timeStr}`;
      } else if (status === 5) {
        return `${timeStr}已使用`;
      } else if (status === 4 || status === 7) {
        return `${timeStr}生效`;
      } else if (status === 9) {
        return `有效时间至${timeStr}`;
      }
    },
    async getCouponList() {
      let res = await this.axios.get("client/v1.Member/getMemberCode");
      if (!res) return;
      this.couponList = res.data;
    },
    
  },
  activated() {
	  setTimeout(async () => {
	    await this.sysConfigAct();
	  }, 300);
    this.getCouponList();
  },
};
</script>

 <style lang="scss">

.coupon-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;

  .item {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 684px;
    height: 167px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    color: #fff;
    &.failed {
      background-image: url("../assets/images/coupon-bg--failed.png");
    }
    &.active {
      background-image: url("../assets/images/coupon-bg--active.png");
    }
    &.inactive {
      background-image: url("../assets/images/coupon-bg--no-active.png");
    }
    &.used {
      background-image: url("../assets/images/coupon-bg--used.png");
    }
    &.invalid {
      background-image: url("../assets/images/coupon-bg--invalid.png");
    }
    .left-box {
      display: flex;
      flex-wrap: wrap;
      text-align: left;
    }
    .first-col {
      padding-left: 20px;
      width: 265px;
      font-size: 20px;
      text-align: left;
      flex-shrink: 0;
      display: flex;
      .num {
        margin-left: 3px;
        font-size: 70px;
      }
    }
    .second-col {
      margin-left: 5px;
      width: 250px;
      text-align: left;
      flex-shrink: 0;
    }
    .active-name {
      width: 480px;
    }
  }
  .active-info-box {
    margin-top: 20px;
    padding: 30px;
    text-align: justify;
    .active-title {
      margin: 0 auto 20px;
      width: 290px;
      font-size: 32px;
      padding: 0 32px 0 35px;
      align-items: center;
      position: relative;
      &::before,
      &::after {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        content: "";
        display: block;
        width: 24px;
        height: 18px;
        background-position: left top;
        background-size: 24px 18px;
      }
      &::before {
        left: 0;
        background-image: url("../assets/images/left-decoration.png");
      }
      &::after {
        right: 0;
        background-image: url("../assets/images/right-decoration.png");
      }
    }
  }
}
</style>