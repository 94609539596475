<template>
  <div class="gift-page">
	   <van-sticky :offset-top="0" v-if="isnull">
	  <form class="serach-box" action="1">
	    <van-search
	      v-model="keyword"
	      shape="round"
	      placeholder="请输入搜索关键词"
	      @search="onSearch"
	    />
	  </form>
	   </van-sticky>
  <van-swipe class="swipe"  indicator-color="white" v-if="adImgList" @change="onChange"> 
    <van-swipe-item v-for="(item, index) in adImgList" :key="index">
		<video v-if="item.video_url" controls  class="video-box"  width="100%"  controlslist="nodownload noremoteplayback" oncontextmenu="return false"
		:src="item.video_url">
			您的浏览器不支持 video 标签。
		<source src type="video/mp4" />
		</video>
      <img v-else v-lazy="item.image" @click="AdgoTo(item.route_type,item.target_id)" />
	 
    </van-swipe-item>
  </van-swipe>
 
    
	<van-row type="flex" v-if="isnull">
		<van-col span="4" > 
			<van-sticky :offset-top="51" class="LeftNav" z-index="88" >
			<van-sidebar v-model="categoryIndex" >
					<van-sidebar-item @click="categoryChange(item.id,item.type)"
					:title="item.cate_name"
					 v-for="(item,index) in gitfCategory" 
					 :key="index" 
					 :dot="item.type == 3 ? true : false"
					 />
				</van-sidebar>
				</van-sticky>
			
		</van-col>
		<van-col span="20" >
			<!-- <van-sticky :offset-top="55"> -->
				<van-row >
					<div class="sxuan" >
					<van-col span="16" ><van-icon name="sort" />
						<div class="left"><a @click="saleFilterChange">销量</a>
						<van-icon name="bar-chart-o" v-if="rank==4" color="#ee0a24"   class="saleacs"/>
						<van-icon name="bar-chart-o" v-else-if="rank==5" color="#ee0a24" class="saledesc" />
						<van-icon name="bar-chart-o" v-else  /></div>
						<div class="left "><a id="rank" @click="rankFilterChange">额度
						<van-icon name="ascending" v-if="rank==2" color="#ee0a24"/>
						<van-icon name="descending" v-else-if="rank==3" color="#ee0a24" />
						<van-icon name="ascending" v-else  /></a></div>
						<div class="clear"></div>
					</van-col>
					<van-col span="8">
						<van-dropdown-menu z-index="99">
						  <van-dropdown-item v-model="range" :options="option2" @change="rangeFilterChange" title-class="menuItem" ref="abc"/>
						</van-dropdown-menu>
					</van-col>
					</div>
				</van-row >
			 
		<!-- 	</van-sticky> -->
			<van-pull-refresh v-model="refreshing" @refresh="onRefresh">
			    <van-list
			      :immediate-check="false"
			      v-model="loading"
			      :finished="finished"
			      :finished-text="giftList.length !== 0 ? '到底了哟~' : ''"
			      @load="onLoad"
			    >
			      <div class="gift-list">
			        <div v-for="(item, index) in giftList" :key="index" class="item gray-shadow" @click="$router.push({name: 'Detail', query: {id: item.id,t:categoryType}})" >
			          <van-image
			            class="img"
			            fit="contain"
			            :src="item.thumbnail_url"
			            
			          ></van-image>
			          <div class="info-box">
			            <p class="title">{{item.gitf_name}}</p>
			            <p class="fz20 col999 line1 mt6" style="text-align: left" v-if="item.small_title">{{item.small_title}}</p>
			            <p class="mark-price">{{item.market_price != 0 ? `市场价 ￥${item.market_price}` : ' '}}</p>
			            <div class="bottom">
			              <span class="num">{{$utils.formatNum(item.quota)}}</span>
			              <span class="limit">额度</span>
			              <span class="paid">已兑:{{item.total_exchange_num}}</span>
			            </div>
			          </div>
			        </div>
			      </div>
			    </van-list>
			    <van-empty v-if="giftList.length === 0" image="search" description="无搜索结果~~" />
			</van-pull-refresh>
		</van-col>
	</van-row>
	
   <van-empty v-if="!isnull" image="search" description="礼券空空如也~~" />

	<cantact-us />
	<div
	  class="notify-icon"
	 v-if="nofityIconHide"
	  @click="showNotify"
	>
	  <van-icon name="bullhorn-o" size="24"  />
	</div>
  </div>
</template>

<script>
import CantactUs from '../components/CantactUs.vue'
import { mapActions, mapState } from "vuex";
export default {
  name: 'Gift',
  components: {
  	CantactUs
  },
  data() {
    return {
      rank: 2,//1-默认序号acs id desc 2-额度 升序 3-额度降序 5-销量升序
      range: 'all',
      keyword: "",
      loading: false,
      finished: false,
      refreshing: false,
      giftList: [],
	adImgList: [],
	gitfCategory: [],
	categoryIndex:0,
	categoryId:0,
	categoryType:0,//1 分类 2标签 3限时抢购
      activeId: '',
      couponId: '',
      option2: [
        {text: '筛选', value: 'all'}
      ],
	   container: null,	
	nofityIconHide: false,
	isnull:false,
    };
  },
  watch: {
    keyword(n) {
      if(n === '') {
        this.onSearch()
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    //点击对应的券跳转过来的，需要显示券对应的活动和面额的礼品
    if(from.name === 'MyCoupon' && to.query.couponId) {
      next(vm => vm.getSpecActiveGiftList())
      return
    }
    if(from.name === null) {
      //直接刷新的
      next(vm => vm.getGiftList())
      return
    } 

    if(from.name === 'Detail') {
      document.documentElement.scrollTop = to.meta.scrollTop
      next()
      return
    }

    next(vm => {
      if(vm.couponId) {
         vm.reset()
         return
      }

      if(vm.giftList.length === 0) {
        vm.getGiftList()
      }
    })
  },
  beforeRouteLeave(to, from, next) {
    if(to.name === 'Detail') {
      from.meta.scrollTop = document.documentElement.scrollTop
    }
    next()
  },
  computed: {
    ...mapState(["sysConfig"]),
    canShowNotify() {
      return this.sysConfig?.exchange_notice_switch === 1;
    },
  },
  methods: {
	  ...mapActions(["sysConfigAct"]),
	saleFilterChange() {
		if(this.rank==4){
		  this.rank=5
	  }else if(this.rank==5){
		  this.rank=4
	  }else{
		   this.rank=5
	  }
		this.onSearch()
	},
    rankFilterChange() {
	  if(this.rank==2){
		  this.rank=3
	  }else if(this.rank==3){
		  this.rank=2
	  }else{
		   this.rank=2
	  }
      this.onSearch()
    },
    rangeFilterChange() {
      this.onSearch()
    },
	categoryChange(id,type) {
		if(id!=this.categoryId||type!=this.categoryType){
			this.categoryId=id;
			this.categoryType=type;
			this.onSearch();
		}
	},
    reset() {
      this.couponId = ''
      this.activeId = ''
      this.giftList = []
      this.finished = false
      if(this.keyword === '') {
        this.getGiftList()
      } else {
        this.keyword = ''
      }
    },
    onLoad() {
      this.getGiftList()
    },
    onSearch() {
      this.giftList = []
      this.loading = true
      this.finished = false
      this.getGiftList()
    },
	onChange(){
		let videoCount=document.getElementsByClassName("video-box");
		 for(let item of videoCount) {
			 item.pause();
		}
		
	},
	
	AdgoTo(route_type,target_id){
		//跳转
		if(target_id==this.categoryId&&route_type==this.categoryType){
			return;
		}
		if(route_type==1||route_type==2){
			//1分类 2标签
			this.categoryId=target_id
			this.categoryType=route_type
			
		this.categoryIndex= this.gitfCategory.findIndex(item=>{
			//id 和类型都一致
			if(item.id==target_id&&item.type==route_type){
				return true
			}
		})			
		this.onSearch()
		}else if(route_type==3){
			//礼品详情
			this.$router.push({name: 'Detail', query: {id: target_id}})
		}
	},	
    async onRefresh() {
      this.giftList = []
      this.finished = false
      this.loading = true
      await this.getGiftList()
      this.$toast('已经是最新数据~')
      this.refreshing = false
    },
    //获取特定活动特定兑换券的礼品列表
    getSpecActiveGiftList() {
      this.keyword = ''
      this.giftList = []
	this.categoryId=0
      this.activeId = this.$route.query.activeId
      this.couponId = this.$route.query.couponId
      this.getGiftList()
    },
    async getRange() {
      let res = await this.axios.get('client/v1.gitf/getGitfFilterQuotaRange')
      for(const [key, value] of Object.entries(res.data)) {
        this.option2.push({text: value, value: key})
      }
    },
	async getSlideItem() {
	let res = await this.axios.get('client/v1.gitf/getSlideItem')
		if(res.data) {
			this.adImgList=res.data.slide_list;
			this.gitfCategory=res.data.label_list;
		}
	},	
    async getGiftList() {	
      let cur_page = this.giftList.length / 10 + 1
      let params = {keyword: this.keyword, cur_page, page_num: 10}
      if(this.couponId) {
        params.activity_id = this.activeId || 0
        params.code_id  = this.couponId
      }
      params.quota_range = this.range === 'all' ? undefined : this.range
      params.paixu_type = this.rank
	  params.category_id = this.categoryId;
	  params.category_type = this.categoryType;	  
	  if(this.categoryId==0){
		  this.categoryIndex=0;
	  }
      let res = await this.axios.get('client/v1.Gitf/getGitfList', {params})
      this.loading = false
	  //判断数据是否为空
	  if(res){
		  if(res.data.list.length < 10) {
			this.finished = true
		  }
		  this.giftList.push(...res.data.list)
		  this.isnull=true
	  }else{
		  this.isnull=false
	  }
      
    },
	showNotify() {
	  if (!this.canShowNotify) return;	  
	  this.$dialog.alert({
	    title: this.sysConfig.exchange_notice_title || "公告",
	    message: this.sysConfig.exchange_notice,
	  }).then(() => {
	      setTimeout(() => {
	        this.nofityIconHide = true
	      }, 500)
	    })
	}
  },
  created() {
    this.createdFlag = true
	this.getSlideItem()
	this.getRange()
  },
  activated() {
	if(this.$utils.readlocalStorage('notifyDataMut')==null){
    setTimeout(async () => {
      await this.sysConfigAct();
      this.showNotify();
	  this.$utils.savelocalStorage('notifyDataMut',JSON.stringify({status:0}))
    }, 300);
	}
  },
  
};
</script>

<style lang="scss">
	.van-dialog__message {
	  text-align: left !important;
	  img {
	    max-width: 100% !important;
	  }
	}
	
	.notify-icon {
	  position: fixed;
	  right: 15px;
	  bottom: 20vh;
	  border-radius: 50%;
	  display: flex;
	  align-content: center;
	  justify-content: center;
	  padding: 10px;
	  background-color: rgba(255, 255, 255, 0.8);
	  transition: all 0.2s linear;
	  box-shadow: 0 0 3px #eee;
	  &.hide {
	    transform: translateX(80%);
	  }
	}
.gift-page {	
  font-size: 24px;
  .van-sidebar-item{background-color: white;}
  .van-sidebar-item--select, .van-sidebar-item--select:active{background-color: #f0f3f7;}
  
  .swipe {
    margin-top: 0px;
    background-color: #fff;
	max-height: 300px;
    img,video{
      width: 98%;
      height: auto;
	border-radius: 15px;
    }
  }
  .van-dropdown-menu__bar{
	  height: 80px;
	  .menuItem{font-size: 24px;}
  }
  .sxuan{
	  .van-dropdown-menu__bar{ box-shadow:none}
	  .left {
		  height: 80px;
		  line-height: 80px;
		  background-color:#FFFFFF;
		  justify-content:center;
		  cursor: pointer;
		  font-size: 24px;
		float: left;
		flex-shrink: 0;
		width: 50%;
		.van-icon{vertical-align: sub;}
		.saleacs{transform: rotate(0deg);}
		.saledesc{transform: rotate(180deg);}
	  }
	  
  }
  .clear {
  clear: both;height: 0;width: 0;
  }
  // .van-dropdown-menu__title::after{display: none;}
  
  .serach-box {
    // margin-top: 10px;
    .van-search__content {
      background-color: #f3f3f3;
      i {
        color: #666666;
      }
      input::-webkit-input-placeholder {
        color: #666666;
      }
    }
  }
  .van-sidebar{width: 125px;
	.van-sidebar-item{padding:25px 5px;}
	.van-sidebar-item__text{font-size: 24px;}
  }
  .LeftNav{
	  
	 .van-sticky{
		width: 125px;
		height: calc(100vh - 200px);
		overflow: scroll;
	  }
  }
  .gift-list {
    padding:15px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .item {
      display: flex;
      flex-direction: column;
      flex-shrink: 0;
      margin-bottom: 30px;
      width: 290px;
      height: 480px;
      border-radius: 15px;
      overflow: hidden;
      background-color: #fff;
      .img {
        width: 100%;
        height: 290px;
      }
      .info-box {
        display: flex;
        flex-direction: column;
        flex: 1;
        padding: 0 12px 15px;
        .title {
        padding-top: 10px;
        text-align: left;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        text-overflow: ellipsis;
        overflow: hidden;
        line-height: 34px;
      }
	.line1{margin-top: auto;}	
      .mark-price {
        
        font-size: 24px;
        text-decoration: line-through;
        color: #999;
        text-align: left;
      }
      .bottom {
        display: flex;
        justify-content: space-between;
        font-size: 36px;
        align-items: baseline;
        .num {
          margin-right: 5px;
          color: #ec2c0f;
        }
        .limit {
          font-size: 20px;
          margin-right: auto;
          color: #ec2c0f;
        }
        .paid {
          font-size: 20px;
          text-align: right;
        }
      }
      }
    }
  }
 
}
</style>
